<template>
  <page-content>

    <common-table
      ref="table"
      path="cashOut"
      :columns="columns">

      <template slot="search">
<!--        <a-button  ghost type="primary" @click="add">新增</a-button>-->
      </template>

      <template slot="logo" slot-scope="{record}">
        <a-avatar shape="square" :size="60" :src="record.logo">{{record.title}}</a-avatar>
      </template>

      <template slot="status" slot-scope="{record}">

        <a-dropdown :trigger="['click']" v-if="record.status==0">
          <a-menu slot="overlay" >
            <a-menu-item key="2" @click="handleSetStatus(record,1)">同意</a-menu-item>
            <a-menu-item key="3" @click="handleSetStatus(record,2)">拒绝</a-menu-item>
          </a-menu>
          <a-button type="danger" size="small">待处理 <a-icon type="down"></a-icon></a-button>
        </a-dropdown>

        <a-tag v-else-if="record.status==1" color="green">已发放</a-tag>
        <a-tag v-else color="green">已拒绝</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-message  @click="viewComment(record)"></action-message>
        <action-edit  @click="edit(record)"></action-edit>
        <action-delete  @click="remove(record)"></action-delete>
      </template>

    </common-table>

  </page-content>
</template>
<script>
  export default {
    components: {
    },
    data () {
      return {

      }
    },

    computed: {
      columns () {
        return [
          {
            title: '用户',
            dataIndex: 'nickName',
          },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          {
            title: '金额',
            dataIndex: 'money',
            customRender: (text, row, index) => {
              return text/100
            }
          },
          {
            title: '申请时间',
            dataIndex: 'createdAt'
          },
          {
            title: '状态',
            dataIndex: 'status',
            scopedSlots: { customRender: 'status' }
          }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.newsEdit.show()
      },
      edit (record) {
        this.$refs.newsEdit.show(record)
      },
      handleSetStatus(record,status){

      },
      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
